<template src="@/views/destinos/destinos-template.vue"></template>
<style src="@/views/destinos/destinos.css"></style>
<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    created() {
        this.ApicDestinos();
    },
    name: 'Destinos',
    data() {
        return {
            destinos: null,
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        async ApicDestinos() {
            await axios({
                    method: 'GET',
                    url: `https://noticias.terminaldesantamarta.com/destinos`,
                    responseType: 'json',
                })
                .then(res => {
                    console.log("res", res.data);
                    this.destinos = res.data;
                }).catch(err => console.err(err));
        }
    },
    computed: {
        ...mapState(['BASE_URL'])
    }
}
</script>