<template>
    <section class="container my-5">
        <div class="row">
            <div class="col-12">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="titulo-destacado d-block">
                            <div class="titulos d-block mb-3">
                                <h2 class="font-weight-bold text-uppercase color-principal">Destinos</h2>
                                <h6 class="text-muted">Busca el destino que quieres para tu viaje</h6>
                            </div>
                            <div class="decoracion">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 my-4">
                <div class="grid-lugares">
                    <div class="grid" v-for="(item, index) of destinos" v-bind:key="index">
                        <div class="card card-ciudad">
                            <a target="_blank" :href="item.url" class="btn tema-principal">
                                <i class="icon-price-tag mr-2"></i> Comprar tiquete
                            </a>
                            <img :src="BASE_URL+item.imagen.url" class="card-img-top" alt="terminal-de-santamarta">
                            <div class="card-body">
                                <p class="card-text h4">{{ item.nombre }}</p>
                                <ul>
                                    <li><strong>Distancia:</strong> {{ item.distancia }} Kilometros</li>
                                    <li><i class="icon-clock mr-2"></i> {{ item.horarios }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>